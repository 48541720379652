import { Link } from 'gatsby';
import * as React from 'react';
import { Breadcrumb } from 'semantic-ui-react';

export class BreadcrumbsHeader extends React.Component<
  { pathname: string },
  {}
> {
  public render() {
    const splitPath = this.props.pathname.split('/');
    const breadcrumbs = [];
    const startIndex = splitPath.indexOf('python_api');
    for (let i = startIndex; i <= splitPath.length - 1; i++) {
      if (i != splitPath.length - 1) {
        breadcrumbs.push(
          <Breadcrumb.Section key={i * 2}>
            <Link to={splitPath.slice(0, i + 1).join('/')}>{splitPath[i]}</Link>
          </Breadcrumb.Section>
        );
        breadcrumbs.push(<Breadcrumb.Divider key={i * 2 + 1} />);
      } else {
        breadcrumbs.push(
          <Breadcrumb.Section key={i * 2} active>
            {splitPath[i]}
          </Breadcrumb.Section>
        );
      }
    }
    return <Breadcrumb>{breadcrumbs}</Breadcrumb>;
  }
}
