import * as React from 'react';
import { List } from 'semantic-ui-react';

import { colors } from '../../../components/sidebar/utils/presets';

export interface PythonPropertyProps {
  qualified_name: string;
  name: string;
  description: string;
  return_type_hint: string;
  has_setter: boolean;
  has_deleter: boolean;
}

export class PythonProperty extends React.Component<PythonPropertyProps, {}> {
  public render() {
    return (
      <List.Item as="li">
        <b css={{ marginRight: `0.5ex` }}>{this.props.name}</b>(
        <span css={{ color: `${colors.mondaic.orange} !important` }}>
          {this.props.return_type_hint}
        </span>
        )<b css={{ marginRight: `0.5ex`, marginLeft: `0.5ex` }}>-</b>
        <i>{this.props.description}</i>
      </List.Item>
    );
  }
}
