import { Link } from 'gatsby';
import * as React from 'react';
import { List } from 'semantic-ui-react';

export class PythonSubmoduleList extends React.Component<
  { module_name: string; path_name: string; child_modules: string[] },
  {}
> {
  public render() {
    const sortedChildModules = this.props.child_modules.sort();

    interface TreeNode {
      linkPathName: string;
      children: {
        [key: string]: TreeNode;
      };
    }

    const pathName = this.props.path_name;

    // Construct a tree from the child modules.
    function addToTree(moduleName: string[], tree: TreeNode, path: string) {
      const modulePath = `${path}/${moduleName[0]}`;
      if (!(moduleName[0] in tree.children)) {
        tree.children[moduleName[0]] = {
          linkPathName: modulePath,
          children: {},
        };
      }

      if (moduleName.length > 1) {
        addToTree(
          moduleName.slice(1),
          tree.children[moduleName[0]],
          modulePath
        );
      }
    }

    const moduleTree: TreeNode = { linkPathName: pathName, children: {} };
    for (const m of sortedChildModules) {
      // XXX: Once the version number is encoded in the JSON files this should
      // no longer be needed.
      const versionNumber = pathName.split('/')[2];
      const moduleStart = `${versionNumber}/${this.props.module_name.replace(
        new RegExp('\\.', 'g'),
        '/'
      )}/`;

      addToTree(
        m.replace(moduleStart, '').split('/'),
        moduleTree,
        this.props.path_name
      );
    }

    function createTreeItem(name: string, m: TreeNode, index: number) {
      const hasChildren = Object.entries(m.children).length > 0;
      return (
        <List.Item key={index}>
          <List.Icon
            disabled
            color="orange"
            name={hasChildren ? 'folder' : 'python'}
          />
          <List.Content>
            <List.Description>
              <Link to={m.linkPathName}>{name}</Link>
            </List.Description>
          </List.Content>
          {hasChildren && (
            <List.List
              css={{
                paddingTop: `0.5em !important`,
                paddingLeft: `1.5em !important`,
              }}
            >
              {Object.entries(m.children).map((subM, subIndex) => {
                return createTreeItem(subM[0], subM[1], subIndex);
              })}
            </List.List>
          )}
        </List.Item>
      );
    }

    return (
      <React.Fragment>
        <List size="small">
          {Object.entries(moduleTree.children).map((m, index) => {
            return createTreeItem(m[0], m[1], index);
          })}
        </List>
      </React.Fragment>
    );
  }
}
