import * as React from 'react';
import { List } from 'semantic-ui-react';

import { renderMarkdownPythonAPI } from '../../utils';
import { H3 } from '../../../components/mdx_blocks/header';

export interface PythonExceptionProps {
  qualified_name: string;
  name: string;
  description: string;
  parent_classes: string[];
}

export class PythonException extends React.Component<PythonExceptionProps, {}> {
  public render() {
    return (
      <List.Item>
        <List.Content>
          <H3>{this.props.name}</H3>
          <div css={{ paddingLeft: `1em` }}>
            {'Parent class' +
              (this.props.parent_classes.length > 1 ? `es` : ``)}
            {`: `}
            {this.props.parent_classes.map((p, index) => (
              <code key={index}>{p}</code>
            ))}
            {renderMarkdownPythonAPI(this.props.description)}
          </div>
        </List.Content>
      </List.Item>
    );
  }
}
