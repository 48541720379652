import * as React from 'react';
import { Link } from 'gatsby';
import { Icon, List } from 'semantic-ui-react';

export interface PythonLinkProps {
  name: string;
  linkTo: string;
  rootPath: string;
  linkToParent: boolean;
  iconName: string;
}

export class PythonLink extends React.Component<PythonLinkProps, {}> {
  public render() {
    let linkSegments = this.props.linkTo.split(".");
    if (this.props.linkToParent) {
      linkSegments = linkSegments.slice(0, -1);
    }
    let link = this.props.rootPath + "/" + linkSegments.join("/");
    // Include anchor link.
    if (this.props.linkToParent) {
      link += `#${this.props.linkTo.split(".").slice(-1)[0].toLowerCase().replaceAll("_", "")}`;
    }
    return (
      <List.Item>
        <Link to={link}>
          <Icon name={this.props.iconName} />
          {this.props.name}</Link>
      </List.Item>
    );
  }
}
