import * as React from 'react';
import { Icon, List } from 'semantic-ui-react';

import { H3, H4, H5 } from '../../../components/mdx_blocks/header';

import { PythonFunction, PythonFunctionProps } from './python_function';
import { PythonProperty, PythonPropertyProps } from './python_property';

export interface PythonClassProps {
  qualified_name: string;
  name: string;
  init_documentation: PythonFunctionProps;
  methods: PythonFunctionProps[];
  class_methods: PythonFunctionProps[];
  properties: PythonPropertyProps[];
  path_hash: string;
}

export class PythonClass extends React.Component<
  PythonClassProps,
  { expanded: boolean }
> {
  constructor(props: PythonClassProps) {
    super(props);
    // Expand if the anchor link points to the class name.
    this.state = {
      expanded: props.path_hash == '#' + props.name.toLowerCase(),
    };
  }

  toggleExpandedState() {
    this.setState({ expanded: !this.state.expanded });
  }

  renderMethods() {
    if (!this.props.methods || this.props.methods.length == 0) {
      return <></>;
    }

    return (
      <>
        <H4>Methods</H4>
        {this.props.methods.map((f, index) => (
          <PythonFunction
            header_tag={H5}
            key={index}
            name={f.name}
            description={f.description}
            qualified_name={f.qualified_name}
            signature={f.signature}
            parameters={f.parameters}
            exceptions={f.exceptions}
            return_description={f.return_description}
            return_type_hint={f.return_type_hint}
            path_hash={this.props.path_hash}
          />
        ))}
      </>
    );
  }

  renderClassMethods() {
    if (!this.props.class_methods || this.props.class_methods.length == 0) {
      return <></>;
    }

    return (
      <>
        <H4>Class Methods</H4>
        {this.props.class_methods.map((f, index) => (
          <PythonFunction
            header_tag={H5}
            key={index}
            name={f.name}
            description={f.description}
            qualified_name={f.qualified_name}
            signature={f.signature}
            parameters={f.parameters}
            exceptions={f.exceptions}
            return_description={f.return_description}
            return_type_hint={f.return_type_hint}
            path_hash={this.props.path_hash}
          />
        ))}
      </>
    );
  }

  public render() {
    let icon = (
      <Icon
        name={
          this.state.expanded ? 'minus square outline' : 'plus square outline'
        }
        onClick={() => this.toggleExpandedState()}
      />
    );

    return (
      <>
        <H3
          css={{
            marginTop: `0em !important`,
            marginBottom: `0em !important!`,
            backgroundImage: `unset`,
          }}
          pre={icon}
        >
          {this.props.name}
        </H3>

        {this.state.expanded && (
          <>
            <PythonFunction
              name={this.props.init_documentation.name}
              description={this.props.init_documentation.description}
              qualified_name={this.props.init_documentation.qualified_name}
              signature={this.props.init_documentation.signature}
              parameters={this.props.init_documentation.parameters}
              exceptions={this.props.init_documentation.exceptions}
              return_description={
                this.props.init_documentation.return_description
              }
              return_type_hint={this.props.init_documentation.return_type_hint}
              path_hash={this.props.path_hash}
            />

            {this.props.properties && this.props.properties.length > 0 && (
              <>
                <H4>Properties</H4>
                <List as="ul">
                  {this.props.properties.map((p, index) => (
                    <PythonProperty
                      key={index}
                      qualified_name={p.qualified_name}
                      name={p.name}
                      description={p.description}
                      return_type_hint={p.return_type_hint}
                      has_setter={p.has_setter}
                      has_deleter={p.has_deleter}
                    />
                  ))}
                </List>
              </>
            )}
            {this.renderClassMethods()}
            {this.renderMethods()}
          </>
        )}
      </>
    );
  }
}
